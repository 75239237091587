import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

export const handleError = (error: unknown) => {
  console.error(error);
  const message = errorMessage(error);
  if (message != "canceled") {
    toast.error(message, { autoClose: false });
    Sentry.captureException(error);
  }
};

export const errorMessage = (error: unknown): string => {
  if ((error as any)?.response?.data) {
    error = (error as any).response.data;
  }
  if (error instanceof Error) {
    return error.message;
  } else if (error && typeof error == "object" && typeof (error as any).message == "string") {
    return (error as any).message;
  } else if (typeof error == "string") {
    return error;
  } else {
    return "Unknown error occurred";
  }
};

// catch unhanded errors
window.addEventListener("error", (event: ErrorEvent) => handleError(event.error));
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) => handleError(event.reason));
