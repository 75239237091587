import { lazy } from "react";
import { FormattedMessage } from "react-intl";
import { BarChart2, CheckSquare, PlayCircle, Users } from "react-feather";
import { RouteObject } from "react-router-dom";

import LectorWrapper from "./components/LectorWrapper";
import { Module } from "../../types";

const LectorIndexPage = lazy(() => import("./pages/LectorIndexPage"));

const LectorAnalyticsPage = lazy(() => import("./pages/LectorAnalyticsPage"));
const LectorAreasPage = lazy(() => import("./pages/LectorAreasPage"));
const LectorQuestionPreviewPage = lazy(() => import("./pages/LectorQuestionPreviewPage"));
const LectorQuestionsPage = lazy(() => import("./pages/LectorQuestionsPage"));
const LectorQuestionsSelectPage = lazy(() => import("./pages/LectorQuestionsSelectPage"));
const LectorQuizViewPage = lazy(() => import("./pages/LectorQuizViewPage"));
const LectorQuizzesPage = lazy(() => import("./pages/LectorQuizzesPage"));
const LectorStudentDetailPage = lazy(() => import("./pages/LectorStudentDetailPage"));
const LectorStudentQuizDetailPage = lazy(() => import("./pages/LectorStudentQuizDetailPage"));
const LectorStudentsPage = lazy(() => import("./pages/LectorStudentsPage"));
const LectorVideosPage = lazy(() => import("./pages/LectorVideosPage"));
const LectorVideoViewPage = lazy(() => import("./pages/LectorVideoViewPage"));
const LectorVideoWatchPage = lazy(() => import("./pages/LectorVideoWatchPage"));
const LectorStudyMaterialsPage = lazy(() => import("./pages/LectorStudyMaterialsPage"));

const tabs = [
  {
    id: "analytics",
    name: <div className="d-flex align-items-center gap-2">
      <BarChart2 className="flex-shrink-0" />
      <FormattedMessage id="module.lector.analytics" defaultMessage="Analytics" />
    </div>,
    element: <LectorAnalyticsPage />,
  },
  {
    id: "sep-0",
    name: (
      <div className="titled-hr">
        <hr/>
        <span><FormattedMessage id="module.lector.menu.students" defaultMessage="Students" /></span>
      </div>
    ),
  },
  {
    id: "students",
    name: <div className="d-flex align-items-center gap-2">
      <Users className="flex-shrink-0" />
      <FormattedMessage id="module.lector.students" defaultMessage="Students" />
    </div>,
    element: <LectorStudentsPage />,
  },
  {
    id: "video-watch",
    name: <div className="d-flex align-items-center gap-2">
      <PlayCircle className="flex-shrink-0" />
      <FormattedMessage id="module.lector.video-watch" defaultMessage="Video watch" />
    </div>,
    element: <LectorVideoWatchPage />,
  },
  {
    id: "quizzes",
    name: <div className="d-flex align-items-center gap-2">
      <CheckSquare className="flex-shrink-0" />
      <FormattedMessage id="module.lector.quizzes" defaultMessage="Quizzes" />
    </div>,
    element: <LectorQuizzesPage />,
  },
  {
    id: "sep-1",
    name: (
      <div className="titled-hr">
        <hr/>
        <span><FormattedMessage id="module.lector.menu.content" defaultMessage="Content" /></span>
      </div>
    ),
  },
  {
    id: "areas",
    name: <FormattedMessage id="module.lector.areas" defaultMessage="Areas" />,
    element: <LectorAreasPage />,
  },
  {
    id: "questions",
    name: <FormattedMessage id="module.lector.questions" defaultMessage="Questions" />,
    element: <LectorQuestionsSelectPage />,
  },
  {
    id: "question-preview",
    name: <FormattedMessage id="module.lector.question-preview" defaultMessage="Question preview" />,
    element: <LectorQuestionPreviewPage />,
  },
  {
    id: "videos",
    name: <FormattedMessage id="module.lector.videos" defaultMessage="Videos" />,
    element: <LectorVideosPage />,
  },
  {
    id: "study-materials",
    name: <FormattedMessage id="module.lector.study-materials" defaultMessage="Study materials" />,
    element: <LectorStudyMaterialsPage />,
  }
];

export default {
  route: {
    path: "/lector",
    element: <LectorWrapper tabs={tabs} />,
    children: [
      {
        path: "/lector",
        index: true,
        element: <LectorIndexPage />,
      },
      ...tabs.map((tab) => (tab.element ? (
        {
          path: `/lector/${tab.id}`,
          element: tab.element,
        }
      ) : undefined)).filter(Boolean) as RouteObject[],
      {
        path: "/lector/questions/:categoryId/:topicId",
        element: <LectorQuestionsPage />,
      },
      {
        path: "/lector/questions/:categoryId/:topicId/:area",
        element: <LectorQuestionsPage />,
      },
      {
        path: "/lector/student/:userId",
        element: <LectorStudentDetailPage />,
      },
      {
        path: "/lector/student/:userId/quiz/:quizSessionId",
        element: <LectorStudentQuizDetailPage />,
      },
      {
        path: "/lector/quizzes/:studyGroupId/:linkId",
        element: <LectorQuizViewPage />,
      },
      {
        path: "/lector/videos/:videoId",
        element: <LectorVideoViewPage />,
      }
    ]
  },
  allowed: (userView) => userView.modules.lector,
  name: <FormattedMessage id="module.lector.name" defaultMessage="Lector" />,
  icon: <Users />,
} satisfies Module;
