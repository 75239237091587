import { FunctionComponent, useState, useEffect } from "react";
import { AlignCenter, User, Settings, LogOut, Maximize, MoreHorizontal, Moon, Sun } from "react-feather";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";

import useApp from "../hooks/useApp";
import useUser from "../hooks/useUser";
import { handleError } from "../helpers/error";
import useWindowSize from "../hooks/useWindowSize";
import Notifications from "./Notifications";
import UserViewSelector from "./UserViewSelector";
import { toast } from "react-toastify";

type HeaderType = {
  sidebar: boolean;
  setSidebar: (sidebar: boolean) => void;
};

const Header: FunctionComponent<HeaderType> = ({ sidebar, setSidebar }) => {
  const { auth, storedColorScheme, colorScheme, nextColorScheme, userPic, userViews } = useApp();
  const user = useUser();

  const [navmenu, setNavmenu] = useState(false);
  const [width] = useWindowSize();

  useEffect(() => {
    if (width <= 991) {
      setSidebar(false);
    } else {
      setSidebar(true);
    }
  }, [width]);

  const fullscreen = () => {
    if (!document.fullscreenElement) {
      if(document?.documentElement?.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else {
        toast.error("Fullscreen not supported");
      }
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <div className="page-main-header">
      <div className="main-header-right">
        <div className="main-header-left text-center">
          <div className="logo-wrapper">
            <Link to={`/`}>
              {colorScheme == "light" ? (
                <img src="/images/logo.png" alt="" />
              ) : (
                <img src="/images/logo-dark.png" alt="" />
              )}
            </Link>
          </div>
        </div>
        <div className="mobile-sidebar">
          <div className="media-body text-end switch-sm">
            <label className="switch ms-3">
              <AlignCenter className="font-primary" onClick={() => setSidebar(!sidebar)} />
            </label>
          </div>
        </div>
        <div className="nav-right col pull-right right-menu">
          <ul className={clsx("nav-menus", {"open": navmenu})}>
            <li>
              {/* center */}
            </li>

            {userViews.length > 1 && (
              <li>
                <UserViewSelector />
              </li>
            )}
            
            <li>
              <button className="btn-text text-dark" onClick={fullscreen}>
                <Maximize />
              </button>
            </li>

            <li>
              <button className="btn-text text-dark" onClick={nextColorScheme}>
                {storedColorScheme == undefined && (
                  colorScheme == "light" ? (
                    <Moon style={{opacity: 0.5}} />
                  ) : (
                    <Sun style={{opacity: 0.5}} />
                  )
                )}
                {storedColorScheme == "dark" && <Moon style={{color: "#7e37d8"}} />}
                {storedColorScheme == "light" && <Sun style={{color: "#ffc717"}} />}
              </button>
            </li>

            <Notifications />
            
            <li className="onhover-dropdown">
              {" "}
              <span className="media user-header">
                <img className="img-fluid user-container" src="/images/dashboard/user-container.png" alt="" />
                {userPic ? (
                  <img className="img-fluid user-pfp" src={userPic} alt="" />
                ) : (
                  <img className="img-fluid user-pfp" src="/images/dashboard/user.png" alt="" />
                )}
              </span>
              <ul className="onhover-show-div profile-dropdown">
                <li className="card-g-primary">
                  <h5 className="f-w-600 mb-0">
                    {user.name}
                  </h5>
                </li>
                <li>
                  <Link to={`/user`}>
                    <User />
                    <FormattedMessage id="common.header.profile" defaultMessage="Profile" />
                  </Link>
                </li>
                <li>
                  <Link to={`/user/settings`}>
                    <Settings />
                    <FormattedMessage id="common.header.settings" defaultMessage="Settings" />
                  </Link>
                </li>
                <li onClick={() => signOut(auth).catch(handleError)}>
                  <LogOut />
                  <FormattedMessage id="common.header.logout" defaultMessage="Logout" />
                </li>
              </ul>
            </li>
          </ul>
          <div className="d-lg-none mobile-toggle pull-right" onClick={() => setNavmenu(!navmenu)}>
            <MoreHorizontal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
