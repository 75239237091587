import {
  guidFrom,
  JsonObject,
  ModelData,
  ModelDimension,
  ModelDimensionTypeSelection,
  ModelQueryData,
  ModelQueryMetric,
  StatsAggregator,
  StatsModel,
  User,
} from "@namedicinu/internal-types";

import ApiClient, { ApiOptions } from "./apiClient";
import LocalDatabase from "./localDatabase";

type AnyModelData = ModelQueryData<StatsModel, ModelDimension<StatsModel>, ModelQueryMetric<StatsModel>>;

export default class StatsClient {
  private statsCache: Map<string, AnyModelData> = new Map();

  constructor(
    private apiClient: ApiClient,
    private localDatabase: LocalDatabase,
  ) {}

  async getRemoteStats<M extends StatsModel, MD extends ModelDimension<M>, MQ extends ModelQueryMetric<M>>(
    user: User,
    statsModel: M,
    dimensions: MD[],
    selection: { [K in MD]?: ModelDimensionTypeSelection<M, K> },
    queryMetrics: MQ[],
    apiOptions?: ApiOptions,
  ) {
    const statsCacheKey = guidFrom({
      c: "remote",
      u: user.userId,
      i: statsModel.id,
      d: dimensions as string[],
      s: selection as JsonObject,
      q: queryMetrics as string[],
    });
    const statsCacheEntry = this.statsCache.get(statsCacheKey);
    if (statsCacheEntry) {
      return statsCacheEntry as ModelQueryData<M, MD, MQ>;
    }

    const aggregator = new StatsAggregator(statsModel, "", "");
    const rawRemote = await this.apiClient.getStats(statsModel.id, dimensions, selection, queryMetrics, apiOptions);

    const remote = aggregator.deserializeModelData(rawRemote) as ModelQueryData<M, MD, MQ>;

    this.statsCache.set(statsCacheKey, remote);
    return remote;
  }

  async getLocalStats<M extends StatsModel, MD extends ModelDimension<M>, MQ extends ModelQueryMetric<M>>(
    user: User,
    statsModel: M,
    dimensions: Iterable<MD>,
    selection: { [K in MD]?: ModelDimensionTypeSelection<M, K> },
    queryMetrics: Iterable<MQ>,
  ) {
    const statsCacheKey = guidFrom({
      c: "local",
      u: user.userId,
      i: statsModel.id,
      d: dimensions as string[],
      s: selection as JsonObject,
      q: queryMetrics as string[],
    });
    const statsCacheEntry = this.statsCache.get(statsCacheKey);
    if (statsCacheEntry) {
      return statsCacheEntry as ModelQueryData<M, MD, MQ>;
    }

    const localEntries = (await this.localDatabase.getStatsEntries(user.email, statsModel.id)) as ModelQueryData<
      M,
      MD,
      MQ
    >;
    const aggregator = new StatsAggregator(statsModel, "", "");
    // const selected = localEntries.filter((entry) => {
    //   for (const [dim, value] of Object.entries(selection)) {
    //     if (Array.isArray(value)) {
    //       if (!value.includes(entry[dim])) {
    //         console.log("!=", value, entry[dim]);
    //         return false;
    //       }
    //     } else {
    //       if (entry[dim] !== value) {
    //         console.log("!=", value, entry[dim]);
    //         return false;
    //       }
    //     }
    //   }
    //   return true;
    // }) as ModelQueryData<M, MD, MQ>;
    const local = aggregator.aggregatePrimary(localEntries, dimensions, selection, queryMetrics);

    this.statsCache.set(statsCacheKey, local);
    return local;
  }

  async getStats<M extends StatsModel, MD extends ModelDimension<M>, MQ extends ModelQueryMetric<M>>(
    user: User,
    statsModel: M,
    dimensions: MD[],
    selection: { [K in MD]?: ModelDimensionTypeSelection<M, K> },
    queryMetrics: MQ[],
    apiOptions?: ApiOptions,
  ) {
    const remote = await this.getRemoteStats(user, statsModel, dimensions, selection, queryMetrics, apiOptions);
    const local = await this.getLocalStats(user, statsModel, dimensions, selection, queryMetrics);

    return this.mergeStats(remote, local);
  }

  async storeLocalStats<M extends StatsModel>(user: User, statsModel: M, data: ModelData<M>) {
    const aggregator = new StatsAggregator(statsModel, "", "");
    const rawData = aggregator.serializeModelData(data);
    await this.localDatabase.storeStatsEntries(user.email, statsModel.id, rawData);
  }

  async mergeStats<M extends StatsModel, MD extends ModelDimension<M>, MQ extends ModelQueryMetric<M>>(
    remote: ModelQueryData<M, MD, MQ>,
    local: ModelQueryData<M, MD, MQ>,
  ) {
    return remote.concat(local);
  }

  async clearOldStatsEntries(user: User): Promise<void> {
    return this.localDatabase.clearOldStatsEntries(user.email);
  }
}
