import type { StatsModel } from "./types";

export const quizSessionModel = {
  id: "quizSession",
  dimensions: {
    email: { type: "STRING" },
    country: { type: "STRING" },
    role: { type: "STRING" },
    studyGroupIds: { type: "STRING" },
    // client
    clientId: { type: "STRING" },
    browser: { type: "STRING" },
    os: { type: "STRING" },
    appRelease: { type: "STRING" },
    // selection
    selectionId: { type: "STRING" },
    modeId: { type: "STRING" },
    showAnswers: { type: "BOOLEAN" },
    shuffleAnswers: { type: "BOOLEAN" },
    allQuestionsAtOnce: { type: "BOOLEAN" },
    allowQuestionReminders: { type: "BOOLEAN" },
    selectQuestionsRange: { type: "STRING" },
    selectQuestionAreas: { type: "INTEGER" },
    questionsPerQuiz: { type: "INTEGER" },
    questionsPreddefined: { type: "BOOLEAN" },
    answersToChooseFrom: { type: "INTEGER" },
    timer: { type: "INTEGER" },
    // session
    linkId: { type: "STRING" },
    categoryId: { type: "STRING" },
    // datetime
    date: { type: "DATE" },
    week: { type: "DATE" },
    weekday: { type: "INTEGER" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    score: { type: "NUMERIC" },
    scoreAdj: { type: "NUMERIC" },
    time: { type: "NUMERIC" },
    questions: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    questions: { agg: "SUM", metric: "questions", type: "INTEGER" },
    "describe-total": { agg: "DESCRIBE", metric: "total" },
    "describe-questions": { agg: "DESCRIBE", metric: "questions" },
    "avg-score": { agg: "AVG", metric: "score", type: "NUMERIC" },
    "avg-score-adj": { agg: "AVG", metric: "scoreAdj", type: "NUMERIC" },
    "avg-time": { agg: "AVG", metric: "time", type: "NUMERIC" },
    "total-time": { agg: "SUM", metric: "time", type: "NUMERIC" },
    "email-completness": { agg: "COMPLETENESS", dimension: "email" },
  },
  clustering: ["date", "email", "categoryId"],
} as const satisfies StatsModel;

export const quizQuestionModel = {
  id: "quizQuestion",
  dimensions: {
    // user
    email: { type: "STRING" },
    // question
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    area: { type: "STRING" },
    quid: { type: "STRING" },
    // datetime
    date: { type: "DATE" },
    week: { type: "DATE" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    score: { type: "NUMERIC" },
    scoreAdj: { type: "NUMERIC" },
    time: { type: "NUMERIC" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "avg-score": { agg: "AVG", metric: "score", type: "NUMERIC" },
    "avg-score-adj": { agg: "AVG", metric: "scoreAdj", type: "NUMERIC" },
    "avg-time": { agg: "AVG", metric: "time", type: "NUMERIC" },
    "total-time": { agg: "SUM", metric: "time", type: "NUMERIC" },
    completness: { agg: "COMPLETENESS", dimension: "quid" },
  },
  clustering: ["email"],
} as const satisfies StatsModel;

export const quizAnswerModel = {
  id: "quizAnswer",
  dimensions: {
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    quid: { type: "STRING" },
    option: { type: "STRING" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    correct: { type: "BOOLEAN" },
    toggles: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "avg-correct": { agg: "AVG", metric: "correct", type: "NUMERIC" },
    "avg-toggles": { agg: "AVG", metric: "toggles", type: "NUMERIC" },
  },
  clustering: ["quid"],
} as const satisfies StatsModel;

export const videoSessionModel = {
  id: "videoSession",
  dimensions: {
    // user
    email: { type: "STRING" },
    country: { type: "STRING" },
    role: { type: "STRING" },
    studyGroupId: { type: "STRING" },
    // client
    clientId: { type: "STRING" },
    browser: { type: "STRING" },
    os: { type: "STRING" },
    appRelease: { type: "STRING" },
    // video
    videoId: { type: "STRING" },
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    // datetime
    date: { type: "DATE" },
    week: { type: "DATE" },
    weekday: { type: "INTEGER" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    time: { type: "INTEGER" },
    played: { type: "INTEGER" },
    paused: { type: "INTEGER" },
    age: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "describe-total": { agg: "DESCRIBE", metric: "total" },
    "watch-time": { agg: "SUM", metric: "played", type: "INTEGER" },
    "pause-time": { agg: "SUM", metric: "paused", type: "INTEGER" },
    "avg-watch-time": { agg: "AVG", metric: "played", type: "NUMERIC" },
    "avg-pause-time": { agg: "AVG", metric: "paused", type: "NUMERIC" },
    "avg-age": { agg: "AVG", metric: "age", type: "NUMERIC" },
    "email-completness": { agg: "COMPLETENESS", dimension: "email" },
  },
  clustering: ["date", "email"],
} as const satisfies StatsModel;

export const videoViewModel = {
  id: "videoView",
  dimensions: {
    email: { type: "STRING" },
    videoId: { type: "STRING" },
    offsetBucket: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {},
  queryMetrics: {
    total: { agg: "COUNT" },
    completness: { agg: "COMPLETENESS", dimension: "videoId, offsetBucket" },
  },
  clustering: ["email"],
} as const satisfies StatsModel;

export const studyMaterialSessionModel = {
  id: "studyMaterialSession",
  dimensions: {
    // user
    email: { type: "STRING" },
    country: { type: "STRING" },
    role: { type: "STRING" },
    // client
    clientId: { type: "STRING" },
    browser: { type: "STRING" },
    os: { type: "STRING" },
    appRelease: { type: "STRING" },
    // study material
    studyGroupId: { type: "STRING" },
    studyMaterialId: { type: "STRING" },
    categoryId: { type: "STRING" },
    topicId: { type: "STRING" },
    lectureNumber: { type: "INTEGER" },
    type: { type: "STRING" },
    access: { type: "STRING" },
    // file
    fileType: { type: "STRING" },
    // datetime
    date: { type: "DATE" },
    week: { type: "DATE" },
    weekday: { type: "INTEGER" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    time: { type: "NUMERIC" },
    age: { type: "NUMERIC" },
    // file
    fileSize: { type: "INTEGER" },
    // text
    textWords: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "describe-total": { agg: "DESCRIBE", metric: "total" },
    time: { agg: "SUM", metric: "time", type: "NUMERIC" },
    "avg-time": { agg: "AVG", metric: "time", type: "NUMERIC" },
    "total-time": { agg: "SUM", metric: "time", type: "NUMERIC" },
    "avg-age": { agg: "AVG", metric: "age", type: "NUMERIC" },
    "total-fileSize": { agg: "SUM", metric: "fileSize", type: "INTEGER" },
    "avg-fileSize": { agg: "AVG", metric: "fileSize", type: "NUMERIC" },
    "total-textWords": { agg: "SUM", metric: "textWords", type: "INTEGER" },
    "avg-textWords": { agg: "AVG", metric: "textWords", type: "NUMERIC" },
    "email-completness": { agg: "COMPLETENESS", dimension: "email" },
  },
  clustering: ["date", "email"],
} as const satisfies StatsModel;

export const studyMaterialViewModel = {
  id: "studyMaterialView",
  dimensions: {
    email: { type: "STRING" },
    studyGroupId: { type: "STRING" },
    studyMaterialId: { type: "STRING" },
    page: { type: "INTEGER" },
    access: { type: "STRING" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    time: { type: "NUMERIC" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "total-time": { agg: "SUM", metric: "time", type: "INTEGER" },
    "avg-time": { agg: "AVG", metric: "time", type: "NUMERIC" },
  },
  clustering: ["email"],
} as const satisfies StatsModel;

export const taskModel = {
  id: "task",
  dimensions: {
    type: { type: "STRING" },
    finalState: { type: "STRING" },
    date: { type: "DATE" },
    week: { type: "DATE" },
    weekday: { type: "INTEGER" },
    hour: { type: "INTEGER" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    time: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "COUNT" },
    "total-time": { agg: "SUM", metric: "time", type: "INTEGER" },
    "avg-time": { agg: "AVG", metric: "time", type: "INTEGER" },
  },
  clustering: ["date"],
} as const satisfies StatsModel;

export const firestoreQueryModel = {
  id: "firestoreQuery",
  dimensions: {
    collection: { type: "STRING" },
    method: { type: "STRING" },
    date: { type: "DATE" },
    week: { type: "DATE" },
    timestamp: { type: "TIMESTAMP" },
  },
  metrics: {
    queries: { type: "INTEGER" },
  },
  queryMetrics: {
    total: { agg: "SUM", metric: "queries", type: "INTEGER" },
    "avg-queries": { agg: "AVG", metric: "queries", type: "INTEGER" },
  },
  clustering: ["method", "collection"],
} as const satisfies StatsModel;
